body {
  overflow: hidden;
}

body.showcase {
  overflow: auto;
}

#editor::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#editor {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.message-class a {
  text-decoration: underline;
}

.blocklyScrollbarVertical {
  display: none;
}

.blocklyToolboxDiv {
  color: black;
}

.webdiva{
  z-index: 1;
}
.webdivb{
  z-index: 2;
}

.chakra-collapse {
  overflow: unset !important;
}